import React, { useState, useEffect } from "react";
import { CustomCard, CustomCardContent, CustomCardHeaderWithPopover,Divider } from './CustomComponents';
import Plot from 'react-plotly.js';
import { useGraphContext } from '../GraphContext';
import Spinner from "./Spinner";

const Mileage = ({ submitClicked, handleDownload, error}) => {
    const [histogramData, setHistogramData] = useState(null);
    const [boxPlotData, setBoxPlotData] = useState(null);

    const [histogramData_weight, setHistogramData_weight] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state

    const { filters } = useGraphContext();
    console.log('Graph Data',filters)
    useEffect(() => {
        if (filters) {
            setLoading(true);
             // Set loading to true when filters change
            try {
                const parsedFilters = JSON.parse(filters.yearly_mil)

                const boxplot=filters.box.yearly_mileage__km

                const weights_histo = JSON.parse(filters.weight_histo);

                setHistogramData(parsedFilters);

                setBoxPlotData(boxplot);

                setHistogramData_weight(weights_histo);

                console.log(boxPlotData)
            } catch (error) {          
                console.error('Error parsing JSON:', error);
            } finally {
                setLoading(false); // Set loading to false after data processing
            }
        } else{
            setLoading(false); // Set loading to false after data processing 
        }
    }, [filters]);

    return (
        <div className="KPI_main_div" >
 
        <div>
        <div className="outer_div_title_onTop_of_KPI" style={{ marginTop: '0px' }}>
          <h6 className="title_onTop_of_KPI" >Yearly mileage</h6>
        </div>
  
        <div className="plot_div" >
          <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Histogram' id= 'Yearly_Mileage_histo'
                    content= {
                    `<ul>
                        <li>A histogram is a graphical representation of the distribution of numerical data. It is a type of bar chart that shows the number of observations within different numerical ranges, called bins.</li>
                        <li>The bars in this histogram represent the count of VINs (vehicles) in each bin. It's an absolute count based on your selection on "Filter" page. If several years of operation are selected, a distinct vehicle appears in the histogram once per year of operation.<br />
                            Example: A vehicle sold in 2023 has two years of operation: 2023 and 2024. This means that the distinct vehicle will appear twice in the histogram - once for each of the two years. This also means that the count of vehicles inside the histogram can exceed the number of "activated vehicles".</li>
                        <li>Bin size: 20.000 km</li>
                        <li>The line chart overlaying the histogram represents the relative cumulative count.</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="yearly_mileage_histo"
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                <div className="error_message">
                                    <p>Invalid combination - please check your inputs.</p>
                                </div>
                                ) : histogramData ? (
                                    <Plot
                                      data={histogramData.data}
                                      layout={histogramData.layout}
                                      config={{ responsive: true }}
                                      style={{ width: '100%', height: '100%' }}
                                      useResizeHandler={true}
                                      autosize={true}
                                    />
                                ) : null
                            ) : (
                                <div className="no_vehicles_message" >
                                    <p>No vehicle(s) selected - please apply filter</p>
                                </div>
                     )}
                </CustomCardContent>
            </CustomCard>
            </div>
            <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Boxplot' id= 'Yearly_Mileage_box'
                    content= {
                    `<ul>
                        <li>A boxplot is another chart used to show the distribution of numerical data.</li>
                        <li>This is done by giving five-number summary on the set of data:
                            <ul>
                            <li>5th percentile ("min")</li>
                            <li>25th percentile ("q1" or first quartile)</li>
                            <li>50th percentile ("median"): the middle value in the data set</li>
                            <li>75 th percentile ("q3" or third quartile)</li>
                            <li>95th percentile ("max")</li>
                            </ul>
                            </li>
                        <li>The boxplot visualization helps especially in understanding distribution differences between different years.</li>
                    </ul>`
                    }
                    handleDownload={handleDownload}
                    downloadOption="yearly_mileage__km" 
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : boxPlotData ? (
                                    <Plot
                                      data={boxPlotData.data}
                                      layout={boxPlotData.layout}
                                      config={{ responsive: true }}
                                      style={{ width: '100%', height: '100%' }}
                                      useResizeHandler={true}
                                      autosize={true}
                                    />
                                    ) : null
                                ) : (
                                    <div className="no_vehicles_message" >
                                        <p>No vehicle(s) selected - please apply filter</p>
                                    </div>
                        )}
                </CustomCardContent>
            </CustomCard>
            </div>
          </div>
        </div>
  
        <div style={{ marginTop: '20px'}}>
        <div className="outer_div_title_onTop_of_KPI" >
          <h6 className="title_onTop_of_KPI" >Relative mileage per payload class</h6>
        </div>

        <div className="plot_div" >
          <div style={{ flex: 1 }}>
            <CustomCard>
                <CustomCardHeaderWithPopover title='Histogram' id= 'weight_histo'
                    content= {
                    `<ul>
                        <li>A histogram is a graphical representation of the distribution of numerical data. It is a type of bar chart that shows the number of observations within different numerical ranges, called bins.</li>
                        <li>The bars in this histogram represent the relative mileage of the selected vehicle(s) per payload class (bin). The histograms helps you understand the distribution on mileage driven per payload class. It is a relative visualisation - you can for example see that there is twice the amount of mileage driven with a payload of 10 tons compared to a payload of 20 tons.</li>
                        <li>Note: payload information is most accurate for "Long Haul Tractors" - other vehicle types may have deviations in payload information</li>
                        <li>Bin size: 2 t</li>
                    </ul>`
                    } 
                    handleDownload={handleDownload}
                    downloadOption="weight_histo"
                />
                <Divider className="divider" />
                <CustomCardContent>
                    {submitClicked ? (
                        loading ? (
                            <Spinner />
                                ) : error ? (
                                    <div className="error_message">
                                        <p>Invalid combination - please check your inputs.</p>
                                    </div>
                                    ) : histogramData_weight ? (
                                    <Plot
                                      data={histogramData_weight.data}
                                      layout={histogramData_weight.layout}
                                      config={{ responsive: true }}
                                      style={{ width: '100%', height: '100%' }}
                                      useResizeHandler={true}
                                      autosize={true}
                                    />
                                    ) : null
                                ) : (
                                    <div className="no_vehicles_message" >
                                        <p>No vehicle(s) selected - please apply filter</p>
                                    </div>
                    )}
                </CustomCardContent>
            </CustomCard>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default Mileage;

{/* <div sx={{ flex: '0 0 50%', minWidth: '300px' }} */}


