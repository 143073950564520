import React, { useEffect, useState } from "react";
import { useGraphContext } from '../GraphContext';

const UserGraph = ({ userId }) => {
  const [graphUrl, setGraphUrl] = useState("");
  const [loading, setLoading] = useState(true); // Loading state

  const { filters } = useGraphContext();

  useEffect(() => {
    if (filters) {
        try {
            const test = filters.graphs.graph_url;
            setGraphUrl(test);

           

        } catch (error) {
            console.error('Error parsing JSON:', error);
        } finally {
          setLoading(false); // Set loading to false after data processing
      }
    }
    else{
      setLoading(false); // Set loading to false after data processing 
  }
}, [filters]);

  return (
    <div>
      <h3>User {userId} Graph</h3>
      {graphUrl ? (
        <iframe
          src={graphUrl}
          width="100%"
          height="500px"
          style={{ border: "none" }}
          title="User Graph"
        ></iframe>
      ) : (
        <p>Loading graph...</p>
      )}
    </div>
  );
};

export default UserGraph;
